export const list = [
  {
    name: 'Google Sheets',
    id: 'google_sheets',
    type: 'integration',
    logo: 'google_sheets.png',
    description:
      'Convert your Google Sheets into JSON API and get, add, update, & delete rows with simple API.',
    published: true,
    category: 'Data',
    docSlug: 'google-sheets-api',
  },
  {
    name: 'Instagram',
    id: 'instagram',
    type: 'integration',
    logo: 'instagram.png',
    description: 'Get your Instagram feed data with JSON API without any coding.',
    published: true,
    category: 'Social Network',
    docSlug: 'instagram-api',
  },
  {
    name: 'Airtable',
    id: 'airtable',
    type: 'integration',
    logo: 'airtable.png',
    description: 'Quickly Convert your Airtable data sheet into secrue API and bypass API limits.',
    published: true,
    category: 'Data',
    docSlug: 'airtable-api',
  },
  {
    name: 'Telegram Bot',
    id: 'telegram',
    type: 'integration',
    logo: 'telegram.png',
    description: 'Send data into your telegram channel, group with simple endpoint.',
    published: true,
    category: 'Automation',
    docSlug: 'telegram-api',
  },
  {
    name: 'Open Weather',
    id: 'ow',
    type: 'integration',
    logo: 'ow.png',
    description: 'Make Open Weather API and bypass the free limit without code.',
    published: true,
    category: 'Open API',
    docSlug: 'open-weather-map-api',
  },
  {
    name: 'Currency Exchange',
    category: 'Data',
    description:
      'Currency exchange rates JSON API for real-time of 144 Countries with simple REST API.',
    logo: 'cx.png',
    id: 'cx',
    type: 'devapis',
    published: true,
    docSlug: 'currency-exchange-api',
  },
  {
    name: 'Webflow',
    id: 'webflow',
    type: 'integration',
    logo: 'webflow.png',
    description: 'Get your webflow CMS collections data as JSON API without any backend',
    published: true,
    category: 'CMS',
    docSlug: 'webflow-cms-api',
  },
  {
    name: 'XML to JSON',
    id: 'xml_to_json',
    logo: 'xml_to_json.png',
    description: 'Convert XML or RSS feed urls into JSON API endpoints.',
    published: true,
    category: 'Parsing',
    docSlug: 'xml-to-json-api',
  },
  {
    name: 'Link Preview',
    id: 'link_preview',
    logo: 'link_preview.png',
    description: 'Extract metadata of any web URL as title, description, ogImage, url, & more.',
    published: true,
    category: 'Development',
    docSlug: 'link-preview-api',
  },
  {
    name: 'Uptime Robot',
    id: 'uptime',
    type: 'integration',
    logo: 'uptime.png',
    description: "Access your website's uptime robot data with simple REST API.",
    published: true,
    category: 'Data',
    docSlug: 'uptime-robot-api',
  },
  {
    name: 'YouTube',
    id: 'yt',
    type: 'integration',
    logo: 'yt.png',
    description: 'Access your youtube channel data with JSON API without any coding.',
    published: true,
    category: 'Videos',
    docSlug: 'youtube-api',
  },
  {
    name: 'Spotify',
    id: 'spotify',
    type: 'integration',
    logo: 'spotify.jpg',
    description:
      'Get Spotify metadata about music artists, albums, and tracks, directly from the Spotify Data Catalogue.',
    published: true,
    category: 'Social Network',
    docSlug: 'spotify-api',
  },
  {
    name: 'Medium',
    id: 'medium',
    type: 'integration',
    logo: 'medium.png',
    description: 'Convert your Medium RSS feeds into JSON APIs and embeded into your website.',
    published: true,
    category: 'Parsing',
    docSlug: 'medium-api',
  },
  {
    name: 'Calendly',
    id: 'calendly',
    type: 'integration',
    logo: 'calendly.png',
    description: 'Get all the events & invitees of your Calendly database with one click.',
    published: true,
    category: 'Development',
    docSlug: 'calendly-api',
  },
  {
    name: 'Twitter',
    id: 'twitter',
    type: 'integration',
    logo: 'twitter.png',
    description:
      'Simple & easy way to use Twitter APIs to search, tweets, followers, & more in few clicks',
    published: true,
    category: 'Social Network',
    docSlug: 'twitter-api',
  },
  {
    name: 'GitHub',
    id: 'github',
    type: 'integration',
    logo: 'github.png',
    description: 'Access your GitHub information without any rate limits.',
    published: true,
    category: 'Development',
    docSlug: 'github-api',
  },
  {
    name: 'Vimeo',
    id: 'vimeo',
    type: 'integration',
    logo: 'vimeo.png',
    description: "Get your vimeo video's data, search videos, channel videos, & more.",
    published: true,
    category: 'Video',
    docSlug: 'vimeo-api',
  },
  {
    name: 'Shopify',
    id: 'shopify',
    type: 'integration',
    logo: 'shopify.png',
    description:
      'Easiest way to connect with your Shopify Store without coding and get customers, Orders, Products, & more data.',
    published: true,
    category: 'CRM',
    docSlug: 'shopify-api',
  },
  {
    name: 'CSV to JSON',
    id: 'csv2json',
    type: 'integration',
    logo: 'csv2json.png',
    description:
      'Convert your CSV data into JSON API without code and also get data with pagination as your requirements.',
    published: true,
    category: 'Finance',
    docSlug: 'csv2json-api',
  },
  {
    name: 'HubSpot',
    id: 'hubspot',
    type: 'integration',
    logo: 'hubspot.png',
    description:
      'Easiest way to connect with your HubSpot CRM, CMS, Marketing, Analytics Data without coding.',
    published: true,
    category: 'CRM',
    docSlug: 'hubspot-api',
  },
  {
    name: 'Google Analytics',
    id: 'ga',
    type: 'integration',
    logo: 'ga.png',
    description: 'Get your google analytics data like pageviews, users, browsers, and more.',
    published: true,
    category: 'Data',
    docSlug: 'google-analytics-api',
  },
  {
    name: 'Notion',
    id: 'notion',
    type: 'integration',
    logo: 'notion.png',
    description: 'Connect Notion pages and databases without any code and secure api key.',
    published: true,
    category: 'Social Network',
    docSlug: 'notion-api',
  },
  {
    name: 'Google Calendar',
    id: 'calendar',
    type: 'integration',
    logo: 'calendar.png',
    description:
      'Get your google calendars, events data as JSON format with simple API endpoints without coding.',
    published: true,
    category: 'Development',
    docSlug: 'google-calendar-api',
  },
  {
    name: 'Google Drive',
    id: 'drive',
    type: 'integration',
    logo: 'drive.png',
    description: 'Get your drive files as JSON & upload to your drive with single API endpoint.',
    published: true,
    category: 'Development',
    docSlug: 'google-drive-api',
  },
  {
    name: 'Google Big Query',
    id: 'bigquery',
    type: 'integration',
    logo: 'bigquery.png',
    description: 'Integrate with Big Query API endpoint.',
    published: true,
    category: 'Development',
    docSlug: 'google-bigquery-api',
  },
  {
    name: 'Google Big Table',
    id: 'bigtable',
    type: 'integration',
    logo: 'bigtable.png',
    description: 'Integrate with Big Table API endpoint.',
    published: true,
    category: 'Development',
    docSlug: 'google-bigtable-api',
  },
  {
    name: 'WordPress',
    id: 'wordpress',
    type: 'integration',
    logo: 'wordpress.png',
    description: 'Convert your WordPress CMS into secure API endpoints without code.',
    published: true,
    category: 'CMS',
    docSlug: 'wordpress-api',
  },
  {
    name: 'Email Verification',
    category: 'Verification',
    description:
      'Powerful email verification API based on SMTP(Simple Mail Transfer Protocol), Spell checks, disposable filtering, DNS validation.',
    logo: 'email-verify.png',
    id: 'email-verify',
    type: 'integration',
    published: true,
    docSlug: 'email-verification-api',
  },
  {
    name: 'Website Screenshot',
    description: 'Capturing Screenshot of any web URL & access remote URL anytime is super easy.',
    id: 'screen',
    type: 'integration',
    logo: 'screen.png',
    published: true,
    category: 'Project Management',
    docSlug: 'screenshot-api',
  },
  {
    name: 'IP Geolocation',
    category: 'Lookup',
    description: 'A real time & free IP address to geo location JSON data with REST API.',
    logo: 'lookup.png',
    id: 'lookup',
    type: 'integration',
    published: true,
    docSlug: 'ip-geolocation-api',
  },
  {
    name: 'Slack Hooks',
    id: 'slack',
    type: 'integration',
    logo: 'slack.png',
    description: 'Build Slack Webhook to send push notifications into channels.',
    published: true,
    category: 'Automation',
    docSlug: 'slack-api',
  },
  {
    name: 'HTML to PDF',
    description:
      'Convert webpages into PDF & store on the CDN to access via remote URL with simple REST API.',
    id: 'pdf',
    type: 'integration',
    logo: 'pdf.png',
    published: true,
    category: 'Project Management',
    docSlug: 'html2pdf-api',
  },
  {
    name: 'Firebase Admin',
    id: 'fbsdk',
    type: 'integration',
    logo: 'fbsdk.png',
    description:
      'Connect with Firebase Admin without any coding setup and aceess the resources with REST API endpoints.',
    published: true,
    category: 'Development',
    docSlug: 'firebase-admin-api',
  },
  {
    name: 'Algolia',
    id: 'algolia',
    type: 'integration',
    logo: 'algolia.png',
    description:
      'Sync your data with Algolia and add Advanced search feature into your projects without any backend.',
    published: true,
    category: 'Data Helpers',
    docSlug: 'algolia-api',
  },
  {
    name: 'Stripe',
    id: 'stripe',
    type: 'integration',
    logo: 'stripe.png',
    description:
      'Get your Stripe data with simple & easy API endpoints like subscriptions, customers, Invoices, & more',
    published: true,
    category: 'Finance',
    docSlug: 'stripe-api',
  },
  {
    name: 'Pipedrive',
    id: 'pipedrive',
    type: 'integration',
    logo: 'pipedrive.png',
    description: 'Get Pipedrive data as JSON APIs without code.',
    published: true,
    category: 'Development',
    docSlug: 'pipedrive-api',
  },
  {
    name: 'Cloudinary',
    id: 'cloudinary',
    type: 'integration',
    logo: 'cloudinary.png',
    description: 'Use simple endpoint to upload files to your Cloudinary account.',
    published: true,
    category: 'Development',
    docSlug: 'cloudinary-api',
  },
  {
    name: 'FaunaDB',
    id: 'fauna',
    type: 'integration',
    logo: 'fauna.png',
    description: 'Get your fauna DB collections data without any server setup & coding',
    published: true,
    category: 'CML',
    docSlug: 'fauna-api',
  },
  {
    name: 'AWS S3',
    id: 's3',
    type: 'integration',
    logo: 's3.png',
    description: 'Connect with Amazon S3 to access & upload object without any coding',
    published: true,
    category: 'CML',
    docSlug: 'aws-s3-api',
  },
  {
    name: 'Contentful',
    id: 'contentful',
    type: 'integration',
    logo: 'contentful.png',
    description: 'Get Contentful CMS data with simple & secure API endpoints.',
    published: true,
    category: 'Headless CMS',
    docSlug: 'contentful-api',
  },
  {
    name: 'Unsplash',
    id: 'unsplash',
    type: 'integration',
    logo: 'unsplash.png',
    description: 'Search images on Unsplash with simple API endpoint without any coding.',
    published: true,
    category: 'Development',
    docSlug: 'unsplash-api',
  },
  {
    name: 'Google Search Console',
    category: 'Data',
    type: 'integration',
    logo: 'webmaster.png',
    description: 'Get Google Search Console (Webmaster) data with simple JSON API.',
    id: 'webmaster',
    published: true,
    docSlug: 'webmaster-api',
  },
  {
    name: 'Google Fit',
    id: 'fit',
    type: 'integration',
    logo: 'fit.png',
    description: 'Access your Fitness data as JSON without any coding.',
    published: true,
    category: 'Finance',
    docSlug: 'google-fitness-api',
  },
  {
    name: 'Ghost CMS',
    id: 'ghost',
    type: 'integration',
    logo: 'ghost.png',
    description: 'Connect with your Ghost CMS & get the data with JSON APIs.',
    published: true,
    category: 'CMS',
    docSlug: 'ghost-api',
  },
  {
    name: 'Supabase',
    id: 'supabase',
    type: 'integration',
    logo: 'supabase.png',
    description: 'Get the supabase database tables data as JSON without any coding & server setup.',
    published: true,
    category: 'Database',
    docSlug: 'supabase-api',
  },
  {
    name: 'Pocket',
    id: 'pocket',
    type: 'integration',
    logo: 'pocket.png',
    description: 'Get Your Pocket data with JSON APIs in one click.',
    published: true,
    category: 'Development',
    docSlug: 'pocket-api',
  },
  {
    name: 'Smartsheet',
    id: 'smartsheet',
    type: 'integration',
    logo: 'smartsheet.png',
    description: 'Use your Smartsheet data with simple JSON API endpoints.',
    published: true,
    category: 'Database',
    docSlug: 'smartsheet-api',
  },
  {
    name: 'Gumroad',
    id: 'gumroad',
    type: 'integration',
    logo: 'gumroad.png',
    description: 'Connect with Gumroad API without any coding and use with any application',
    published: true,
    category: 'Finance',
    docSlug: 'gumroad-api',
  },
  {
    name: 'MailChimp',
    id: 'mailchimp',
    type: 'integration',
    logo: 'mailchimp.jpg',
    description: 'Get your mailchimp data with NoCodeAPI in less than 5 minutes.',
    published: true,
    category: 'Marketing',
    docSlug: 'mailchimp-api',
  },
  {
    name: 'Twilio',
    id: 'twilio',
    type: 'integration',
    logo: 'twilio.png',
    description: 'Send SMS to mobile with simple and secure endpoint without any coding.',
    published: true,
    category: 'Social Network',
    docSlug: 'twilio-api',
  },
  {
    name: 'Netlify',
    id: 'netlify',
    type: 'integration',
    logo: 'netlify.png',
    description:
      'Setup your netlify API to access netlify form data, sites, domains, & more from your netlify account with nocode.',
    published: true,
    category: 'Development',
    docSlug: 'netlify-api',
  },
  {
    name: 'Mailgun',
    id: 'mailgun',
    type: 'integration',
    logo: 'mailgun.png',
    description:
      'Mailgun is more developer friendly email marketing tool to send Transactional emails.',
    published: true,
    category: 'Marketing',
    docSlug: 'mailgun-api',
  },
  {
    name: 'Zoho Mail',
    id: 'zohomail',
    type: 'integration',
    logo: 'zohomail.png',
    description:
      'Setup Zoho email API to send transactional email without any server setup or coding.',
    published: true,
    category: 'Mail',
    docSlug: 'zoho-mail-api',
  },
  {
    name: 'Monday.com',
    description: 'Connect with your Monday.com account and get the data as JSON without coding.',
    id: 'mondaydotcom',
    type: 'integration',
    logo: 'mondaydotcom.png',
    published: true,
    category: 'Project Management',
    docSlug: 'monday-dot-com-api',
  },
  {
    name: 'Zoho Sheet',
    description: 'Alternative to google sheet, access zoho spreadsheet data with JSON API.',
    id: 'zohosheet',
    type: 'integration',
    logo: 'zohosheet.png',
    published: true,
    category: 'Project Management',
    docSlug: 'zoho-sheet-api',
  },
  {
    name: 'Snipcart',
    id: 'snipcart',
    type: 'integration',
    logo: 'snipcart.png',
    description: 'Connect with your e-commerce data with simple API without code.',
    published: true,
    category: 'Social Network',
    docSlug: 'snipcart-api',
  },
  {
    name: 'Facebook Pages',
    description: 'Get your Facebook pages data as JSON with simple API endpoint.',
    id: 'fbpages',
    type: 'integration',
    logo: 'fbpages.png',
    published: true,
    category: 'Project Management',
    docSlug: 'facebook-pages-api',
  },
  {
    name: 'Typeform',
    id: 'typeform',
    type: 'integration',
    logo: 'typeform.png',
    description: 'Collect your forms data into JSON format & Transformed.',
    published: true,
    category: 'Data Helper',
    docSlug: 'typeform-api',
  },
  {
    name: 'Mailerlite',
    id: 'mailerlite',
    type: 'integration',
    logo: 'mailerlite.png',
    description: 'Get your mailerlite data with NoCodeAPI in less than 5 minutes.',
    published: true,
    category: 'Data Helper',
    docSlug: 'mailerlite-api',
  },
  {
    name: 'GoodReads',
    id: 'gr',
    type: 'integration',
    logo: 'gr.png',
    description:
      'Search goodreads database for your books, author, saved books and get JSON results.',
    published: true,
    category: 'Data',
    docSlug: 'goodreads-json-api',
  },
  // {
  //     name: 'Hubspot',
  //     description:
  //         'Easiest way to get your Hubspot data as JSON with single API endpoint',
  //     id: 'hubspot',
  //     type: 'hubspot',
  //     logo: 'hubspot.png',
  //     published: true,
  //     category: 'Project Management',
  //     docSlug: 'hubspot-api',
  // },
  {
    name: 'Endpoint Proxy',
    id: 'ep',
    type: 'integration',
    logo: 'ep.png',
    description: 'Convert any HTTP request to proxy and secure keys without server & coding',
    published: true,
    category: 'Development',
    docSlug: 'endpoint-api-proxy',
  },
  {
    name: 'Google Trends',
    id: 'gTrends',
    logo: 'gTrends.png',
    description: 'Get Google Trends Data for keywords as JSON results',
    published: true,
    category: 'Data',
    docSlug: 'google-Trends-api',
  },
  {
    name: 'NoCode Forms',
    id: 'nForms',
    logo: 'nForms.png',
    description: 'Store result from any forms, or get it delivered to you via email',
    published: true,
    category: 'Data',
    docSlug: 'nocode-forms-api',
  },
  {
    name: 'MongoDB',
    id: 'databaseMongoApi',
    logo: 'databaseMongoApi.png',
    description: 'MongoDB API without coding',
    published: true,
    category: 'Data',
    docSlug: 'nocode-mongodb-api',
  },
  {
    name: 'postGreSQL',
    id: 'databasePostgresApi',
    logo: 'databasePostgresApi.png',
    description: 'PostGreSQL Database API without coding',
    published: true,
    category: 'Data',
    docSlug: 'nocode-postgres-api',
  },
  {
    name: 'Enrich Email',
    id: 'enrich',
    logo: 'enrich.png',
    description: 'Enrich email',
    published: true,
    category: 'Data',
    docSlug: 'enrich-email-api',
  },
  {
    name: 'AI ChatGPT',
    id: 'chatgpt',
    logo: 'chatgpt.png',
    description: 'AI Chat GPT',
    published: true,
    category: 'Data',
    docSlug: 'chatgpt-api',
  },
  {
    name: 'Claude AI',
    id: 'claude',
    type: 'integration',
    logo: 'claude.png', // Using ChatGPT logo temporarily, should be replaced with Claude logo
    description: 'Claude AI integration for advanced language processing',
    published: true,
    category: 'Data',
    docSlug: 'claude-ai',
  },
  {
    name: 'QuickBooks',
    id: 'quickbooks',
    logo: 'quickbooks.png',
    description: 'Get invoices, draft invoice, get customers, add or update customers on QuickBook accounting solutions easily',
    published: true,
    category: 'Finance',
    docSlug: 'quickbooks-api',
  },
];
